import React from 'react';

import { ContainerTipoAcesso, BtnGroupContainer } from './style';
import { Button } from 'react-bootstrap';
import { useSearch } from '../../SearchContext';

import { MdSearch } from 'react-icons/md';

export const TipoAcessoCards: React.FC = () => {
  const { tipoAcesso, setTipoAcesso, setValue, searchTextWatch } = useSearch();

  return (
    <ContainerTipoAcesso>
      <BtnGroupContainer>
        <Button
          value="0"
          className={
            tipoAcesso === 0
              ? 'btn-group btn-group-active cards'
              : 'btn-group cards'
          }
          disabled={false}
          onClick={() => {
            setTipoAcesso(0);
          }}
          type="button"
        >
          Meus cards
        </Button>
        <Button
          value="1"
          disabled={false}
          className={
            tipoAcesso === 1
              ? 'btn-group btn-group-active loja'
              : 'btn-group loja'
          }
          onClick={() => {
            setTipoAcesso(1);
          }}
          type="button"
        >
          Loja
        </Button>
        <Button
          value="2"
          disabled={false}
          className={
            tipoAcesso === 2
              ? 'btn-group btn-group-active empresa'
              : 'btn-group empresa'
          }
          onClick={() => {
            setTipoAcesso(2);
          }}
          type="button"
        >
          Grupo de Empresa
        </Button>
        <Button
          value="3"
          disabled={false}
          className={
            tipoAcesso === 3
              ? 'btn-group btn-group-active publico'
              : 'btn-group publico'
          }
          onClick={() => {
            setTipoAcesso(3);
          }}
          type="button"
        >
          Público
        </Button>
      </BtnGroupContainer>
      <div className="containerSearchInput">
        <input
          type="text"
          className="search"
          onChange={(e) => {
            setValue('search', e.target.value);
          }}
          placeholder="Pesquisar"
          value={searchTextWatch}
        />
        <MdSearch />
      </div>
    </ContainerTipoAcesso>
  );
};
