import React from 'react';
import { SearchContextProvider } from './SearchContext';
import { SearchContent } from './SearchContent';
import { SearchProps } from './types';

const Search: React.FC<SearchProps> = ({
  codTela,
  newData,
  orderModulo,
  onRowClick,
  onlyReport,
  orderTelas = false,
}) => {
  return (
    <SearchContextProvider
      codTela={codTela}
      newData={newData}
      orderModulo={orderModulo}
      onRowClick={onRowClick}
      onlyReport={onlyReport}
      orderTelas={orderTelas}
    >
      <SearchContent />
    </SearchContextProvider>
  );
};

export default Search;
